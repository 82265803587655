/**
 * page styles
 *
*/
#home {
}

#home .covid-bar {
  position: absolute;
  bottom: 450px;
  left: 50%;
  z-index: 100;
  width: 500px;
  text-align: center;
  margin-left: -250px;
}



#home .covid-bar .btn {
  border-radius: 0px;
    border: none;
    background-color: #d90000;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.225rem;
    letter-spacing: 0.125rem;
    color: #fff;
    font-weight: 400;
}

#home .intro-bar {
  position: relative;
  z-index: 0;
  width: 100%;
  overflow: hidden;
  max-height: 1100px;
  min-height: 200px;
  height: 80vh;
  background-color: #000;
}

#home .intro-bar .bg-bar {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 0;
  background-color: #000;
  z-index: 0;
}

#home .intro-bar img {
  width: 100%;
  min-height: 100%;
}

#home .stroke-headline-bar-01 {
  position: absolute;
  top: 20%;
  right: 50%;
  z-index: 1;
  white-space: nowrap;
  display: grid;
  justify-content: flex-end;
  width: 50%;
  opacity: 0.5;
  -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}

#home .stroke-headline-bar-01>div {
  width: 100%;
}

#home .stroke-headline-bar-01 div,
#home .stroke-headline-bar-01 span,
#home .stroke-headline-bar-01 h1 {
  color: transparent;
  font-weight: 800;
  font-size: 5.526rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-transform: uppercase;
  animation: ani 7s linear infinite;
  font-family: futura-pt, sans-serif;
}


#home .stroke-headline-bar-01:hover h1,
#home .stroke-headline-bar-01:hover span,
#home .stroke-headline-bar-01:hover div {
  color: #fff!important;
}

#home .stroke-headline-bar-02 {
  position: absolute;
  bottom: 50%;
  left: 50%;
  z-index: 1;
  white-space: nowrap;
  z-index: 1;
  white-space: nowrap;
  display: grid;
  justify-content: flex-end;
  width: 50%;
  opacity: 0.5;
  -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}

#home .stroke-headline-bar-02>div {
  width: 100%;
}

#home .stroke-headline-bar-02 div,
#home .stroke-headline-bar-02 span,
#home .stroke-headline-bar-02 h1 {
  color: transparent;
  font-weight: 800;
  font-size: 5.526rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-transform: uppercase;
  font-family: futura-pt, sans-serif;
  animation: ani 7s linear infinite;
}


#home .stroke-headline-bar-02:hover h1,
#home .stroke-headline-bar-02:hover span,
#home .stroke-headline-bar-02:hover div {
  color: #fff!important;
}

#home .intro-bar .st2 {
}



@keyframes ani {
  0%{
     color: transparent;
     text-shadow: none;
     -webkit-text-stroke-width: 1px;
  }
  50%{
    color:#fff;
    -webkit-text-stroke-width: 0px;
    text-shadow: 0 0 7px #fff,0 0 40px #fff;
  }
  100%{
    color: transparent;
    text-shadow: none;
    -webkit-text-stroke-width: 1px;
  }
}


#home .combined {
  color: #fff;
  width: 316px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}


#home .combined .title {
  font-size: 58px;
  letter-spacing: 8px;
  position: absolute;
  width: 350px;
  overflow: hidden;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#home .combined-bar {
  width: 100%;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 70px;
}

#home .combined .content {
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: 78px;
}






#home .countdown-bar {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  z-index: 1;
  width: 580px;
  margin: auto;

  opacity: 1!important;

  -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

#home .countdown-bar:hover {
  opacity: 1!important;
}

#home .countdown-bar h2 {
  color: #fff;
  font-size: 9.525rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0px;
  text-align: center;
}

#home .countdown-bar h2.date-month {
  letter-spacing: 1.25rem;
  margin-top: 0px;
  font-size: 12.525rem;
  padding-left: 0.5rem;
  margin-top: -2rem;
  line-height: 11rem;
}

#home .countdown-bar h3 {
  color: #fff;
  padding: 10px;
  background-color: #d90000;
  background: none;
  border: 3px #fff solid;
  font-size: 7.225rem;
  line-height: 7.225rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: auto;
  margin-top: 40px;
  letter-spacing: 2.325rem;
  text-align: center;
  position: relative;
  display: inline-block;
  padding-left: 12%;
  padding-right: 8%;
}

#home .countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  left: -5px;
  padding-top: 30px;
}

#home .countdown-item>* {
  z-index: 1;
}

#home .countdown-item {
  color: #fff;
  font-size: 2.325rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 0px;
  padding-top: 10px;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 1;
  font-weight: 800;
  text-align: center;
}

#home .countdown-item span {
  color: #fff;
  font-size: 1.325rem;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
}

#home .countdown-item span.label {
  font-size: 1.025rem;
}

#home .countdown-svg {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  fill: #fff;
  color: #fff;
  z-index: -1;
}

#home .efaGep {
  display: none;
}


#home .countdown-wrapper {
  position: relative;
  left: 0.3rem;
}

#home .video-bar {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 1;
  background-color: #000;
  height: 100%;

  background-repeat: no-repeat;
  background-size: cover;
}

#home .intro-bar::before {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 50%;
  right: 0px;
  z-index: 1;
  background: rgb(43,43,43);
background: linear-gradient(180deg, rgba(43,43,43,1) 0%, rgba(43,43,43,0) 100%);
display: none;
}

#home .video-bar video {
  width: auto;
  min-width: 100%;
  min-height: 100%;
  opacity: 0.6;
  filter: grayscale(1)
}


#home h1 {


  font-weight: 800;
  text-transform: uppercase;
  font-size: 2.125rem;
  letter-spacing: 0.325rem;
  margin-bottom: 30px;
  color: #000;
  font-weight: 800;
  font-size: 10.526rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-transform: uppercase;
  font-family: futura-pt, sans-serif;
  z-index: 1;
  position: relative;
}

#home .arrow-bar {
  position: absolute;
  bottom: 0px;
  left: 50%;
  font-size: 2.525rem;
  color: #fff;
  z-index: 5;
  margin-left: -40px;
}

#home .arrow-bar button {
  background: none !important;
  border: none !important;
  color: #fff !important;

}


.thankyou-bar {
  width: 500px;
  margin: auto;
  position: relative;
  padding-top: 300px;
  z-index: 1;
  color: #fff;
}

.thankyou-bar h5 {
  color: #fff;
  font-size: 2.225rem;
  font-weight: 600;
  margin-top: 20px;
}

.thankyou-bar .price {
  font-size: 1.125rem;
  font-weight: 600;
}


.thankyou-bar .btn {

  background-color: #e20a17;
  border: none;
  color: #fff!important;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.125rem;
  font-size: 1.125rem!important;
  outline: none!important;
  box-shadow: none!important;
  white-space: nowrap;
  border-radius: 0px;
  margin-top: 20px;
}

.thankyou-bar .btn svg {
  margin-right: 20px;
  position: relative;
}







#home .triangle-bar {
  position: absolute;
  z-index: 0;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  overflow: hidden;

}

#home .triangle-bar .animatesTriangleLeft {
}

#home .triangle-bar .animatesTriangleLeft {
  position: absolute;
  width: 300px;
  height: 300px;
  bottom: 00px;
}

#home .triangle-bar .animatesTriangleRight {
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 800px;
  max-width: 170px;
}

#home .triangle-bar .animatesTriangleRight img {
  height: 800px!important;
}



.home-welcome-bar h6 {
  font-size: 3.325rem;
}

.home-welcome-bar .btn {

  background-color: #e20a17;
  border: none;
  color: #fff!important;
  font-family: futura-pt, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.125rem;
  font-size: 1.125rem!important;
  outline: none!important;
  box-shadow: none!important;
  white-space: nowrap;
  border-radius: 0px;
  margin-top: 20px;
}

.home-welcome-bar .btn svg {
  margin-right: 20px;
  position: relative;
}



#home .home-welcome-bar {
  background-color: #fff;
  position: relative;
  z-index: 0;
  padding-top: 100px;
  padding-bottom: 50px;
}

#home .home-welcome-bar .icon-bar {
  font-size: 3.225rem;
  color: #d90000;
  margin-bottom: 30px;
}

#home .home-welcome-bar h1 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.825rem;
  letter-spacing: 0.325rem;
}

#home .home-welcome-bar h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 2.125rem;
  letter-spacing: 0.325rem;
  margin-bottom: 30px;
}


#home .home-welcome-bar h3 {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 2.125rem;
  letter-spacing: 0.325rem;
  margin-bottom: -30px;
  color: #d90000;
  position: relative;
  z-index: 2;
}

#home .home-welcome-bar h4 {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 2.125rem;
  letter-spacing: 0.325rem;
  margin-bottom: 30px;
  color: transparent;
  font-weight: 800;
  font-size: 3.526rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  text-transform: uppercase;
  font-family: futura-pt, sans-serif;
  position: relative;
  z-index: 1;
}

#home .home-welcome-bar .body {
  max-width: 900px;
  margin: auto;
  margin-bottom: 40px;
  line-height: 1.825rem;
  font-size: 1.125rem;
  letter-spacing: 0.125rem;
  font-weight: 300;
}



#home .home-welcome-bar .animatesTriangleLeft svg {
}

#home .home-welcome-bar .animatesTriangleLeft {
  position: absolute;
  left: 0px;
  bottom: -40px;
  width: 230px;
  height: 230px;
}

#home .home-welcome-bar .animatesTriangleRight svg {

}

#home .home-welcome-bar .animatesTriangleRight {
  position: absolute;
  bottom: 0px;
  right: 00px;
  width: 235px;
  height: 235px;
}





#home .home-welcome-bar .fill,

#home .home-welcome-bar.fill polygon {
  fill-opacity: 1;
  stroke-width: 0px;
  transition: all 1s ease-in;
}

#home .home-welcome-bar .st1 {
  fill: #e20a17;
  stroke: #e20a17;
}

#home .home-welcome-bar .animatesTriangleRight svg {
  width: 700px;
  height: 700px;
}








#home .home-lineup-bar {
  position: relative;
  padding-top: 50px;
  cursor: pointer;
  background: #fff;
}

#home .home-lineup-bar #cursor {
  background: none!important;
}

#home .home-lineup-bar #cursor a {
  white-space: nowrap;
}


#home .home-lineup-bar img {
  max-width: 500px;
}

#home .home-lineup-bar a {
  color: #000;
  text-decoration: none;
}

#home .home-lineup-bar h4 {
  font-size: 5.225rem;
  font-weight: 700;
  text-transform: uppercase;
}

#home .home-lineup-bar .btn-artists {
  background: none;
  border: none;
  color: #000;
  position: absolute;
  top: 20%;
  left: 60%;
  font-size: 1.825rem;
  text-transform: uppercase;
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  z-index: 10;
}

#home .home-lineup-bar .swiper-slide .btn-artists {
  position: static;
  left: auto;
  top: auto;
  margin: auto;
}

#home .home-lineup-bar .btn-artists svg {
  color: #e20917;
  font-size: 4.335rem;
}

#home .home-lineup-bar .item {
  padding-left: 60px;
  padding-right: 60px;
}

#home .home-lineup-bar h5 {
  text-transform: uppercase;
  color: #e8e8e8;
  font-size: 25.225rem;
  line-height: 20rem;
  line-height: 20rem;
  white-space: nowrap;
  font-weight: 700;
    overflow: hidden;
}

#home .home-lineup-bar .marquee-container-wrapper {
  margin-top: -235px;
  margin-bottom: -35px;
  position: relative;
  z-index: 0;
}

#home .home-lineup-bar .overlay {
  display: none;
}


#home .home-lineup-bar .animatesTriangleLeft svg {
}

#home .home-lineup-bar .animatesTriangleLeft {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200px;
  z-index: 0;
  height: 200px;
}

#home .home-lineup-bar .animatesTriangleRight svg {

}

#home .home-lineup-bar .animatesTriangleRight {
  position: absolute;
  top: 0px;
  right: 00px;
  width: 335px;
  height: 335px;
  z-index: 0;
}








#covidModal .animatesTriangleRight,
#covidModal .animatesTriangleLeft {
  display: none;
}

#covidModal .page {
  padding-top: 0px;
  padding-bottom: 0px;
}

#covidModalLabel {
  display: none;
}









.home-slogan-bar {
  padding: 10px;
  background-color: #000000;
  position: relative;
  z-index: 1;
  color: #fff;
}

.home-slogan-bar .overlay {
  display: none;
}

.home-slogan-bar .item {
  font-size: 2.925rem;
  letter-spacing: 0.225rem;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  color: transparent;
  -webkit-text-stroke-width: 0.5px;
           -webkit-text-stroke-color: white;
           font-weight: 700;
           font-family: futura-pt, sans-serif;
}





/* responsive */
/* X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {

}

/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {

  #home .thankyou-bar {
    padding-top: 200px;
  }

}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 1199.98px) {
  #home .home-welcome-bar .animatesTriangleLeft,
  #home .home-welcome-bar .animatesTriangleRight,
  #home .home-lineup-bar .animatesTriangleLeft,
  #home .home-lineup-bar .animatesTriangleRight {
    display: none;
  }

  #home .home-lineup-bar h4 {
    font-size: 3.325rem;
  }

  #home .home-lineup-bar img {
    max-width: 300px;
  }


    #home .countdown-bar h3 {
      font-size: 5.225rem;
      line-height: 5.225rem;
    }

    #home h1 {
      line-height: normal;
    }
}

/* Small devices (landscape phones, less than 768px)*/
@media (max-width: 991.98px) {

  #home .countdown-bar h3 {
    padding-right: 7%;
  }

  #home .countdown-wrapper {
    left: 0rem;
  }

  #home .home-lineup-bar .btn-artists {
    overflow: visible;
    margin-top: 10px;
  }

  #home .home-lineup-bar .btn-artists svg {
    font-size: 3.0225rem;
    position: relative;
    top: -10px;
  }

  #home .home-welcome-bar {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  #home .home-welcome-bar h1 {
    padding: 0px;
  }

  #home .home-welcome-bar h2 {
    font-size: 1.525rem;
    padding: 0px;
  }

  body #home {
    padding-top: 0px!important;
  }

  #home .countdown-bar h2 {
    font-size: 10.025rem;
    line-height: 5.525rem;
  }


    #home .thankyou-bar {
      max-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      background-position: left;
    }

    #home .video-bar {
      background-position: left bottom;
    }

    #home .thankyou-bar .btn {
      font-size: 0.925rem!important;
    }



    #home h1 {
      line-height: normal;
    }

}

/* X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 991.98px) {

  #home .countdown-bar h2.date-month {
    margin-top: 1rem;
  }

  #home .home-lineup-bar #cursor {
    display: none!important;
  }

  #home .home-welcome-bar .icon-bar {
    margin-bottom: 0px;
  }


  #home .home-welcome-bar .body {
    font-size: 1.025rem;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 20px;
  }

  #home video {
    position: relative;
}

  #home .arrow-bar {
    display: none;
  }

  #home .intro-bar {
  }

  #home .intro-bar .thankyou-bar {
    padding-top: 90px;
  }

  #home .triangle-bar {
    display: none
  }

  #home .countdown-item {
    padding-left: 20px;
    padding-right: 20px;
  }

  #home .countdown-bar h3 {
    left: 0px;
    text-align: center;
  }

  #home .countdown-bar {
    width: 90%!important;
    margin-left: 5%;
    margin-right: 5%;
    transform: translateX(0px)!important;
    top: 20%;
  }

  #home .countdown-bar h2 {
    font-size: 6.025rem;
    line-height: 3.525rem;
  }

  #home .countdown-bar h3 {
    font-size: 3.225rem;
    line-height: 3.225rem;
  }

#home .stroke-headline-bar-01,
#home .stroke-headline-bar-02 {
  display: none;
}

}



@media (max-width: 576.98px) {

  #home .countdown-bar h3 {
    padding-right: 2%;
    margin: 75px auto auto;
  }

  #home .countdown-bar {
    top: 20%;
  }

  #home .countdown-wrapper {
    padding-left: 0.4rem;
  }

  #home .countdown-item {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 2.025rem;
  }

  #home .countdown-item span {
    font-size: 1.125rem;
  }

  #home .countdown-bar h2 {
    font-size: 6.525rem;
    line-height: 3rem;
    margin-bottom: 2rem;
  }

  #home .countdown-bar h2.date-month {
    font-size: 7.525rem;
    padding-left: 1rem;
    margin-top: 0rem;
    margin-bottom: -1rem;
    line-height: 3rem;
    padding: 0;
  }
}


@media (max-width: 414.98px) {

  #home .countdown-item {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1.725rem;
  }

  #home .countdown-item span {
    font-size: 1.025rem;
  }


}
